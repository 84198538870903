<template>
  <div class="product-name-page-box">
    <el-cascader
      ref="cascader"
      v-model="value"
      :options="pnameArr"
      :props="{value:'code',label:'goodsName'}"
      placeholder="请选择品名"
      @change="handleChange"
    />
  </div>
</template>

<script>
import { getGoodsList } from '@/api/goods'
import { translateDataToTree } from '@/utils/util'
export default {
  props: {
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: [],
      pnameArr: []
    }
  },
  watch: {
    defaultValue: {
      handler(newVal) {
        this.value = [...newVal]
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    getGoodsList(res => {
      this.pnameArr = translateDataToTree(res.data, 'parentCode', 'code')
    }, () => { }, '')
  },
  methods: {
    handleChange(arr) {
      const pathLabels = arr.length > 0 ? this.$refs['cascader'].getCheckedNodes()[0].pathLabels : []
      this.$emit('getProduct', { value: arr, label: pathLabels })
    }
  }
}
</script>
