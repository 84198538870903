<template>
  <!-- 新增入库页面 -->
  <div class="stockStorage page-info-content">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      label-position="left"
      label-width="130px"
      class="demo-ruleForm  page-form form-item-row"
    >
      <el-form-item label="入库类型" prop="type">
        <el-radio-group v-model="ruleForm.storageType" @change="getContractNo">
          <el-radio :label="1">
            普通入库
          </el-radio>
          <el-radio :label="2">
            合同入库
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="合同编号"
        :prop="ruleForm.storageType === 2 ? 'contractNo' : ''"
      >
        <el-input
          v-model="ruleForm.contractNo"
          placeholder="订单入库请先填写合同编号联动数据"
          @change="getContractNo(2)"
        />
      </el-form-item>
      <el-form-item label="仓库名称" prop="warehouseId">
        <el-select
          v-model="ruleForm.warehouseId"
          :disabled="ruleForm.storageType === 2"
          class="cmpNameSearch"
          filterable
          reserve-keyword
          placeholder="请选择"
          :remote-method="remoteMethod2"
          @change="addGoodsAllocation"
        >
          <el-option
            v-for="item in warehouseListArr"
            :key="item.id"
            :label="item.warehouseName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="货主名称" prop="custId">
        <el-select
          v-model="ruleForm.custId"
          :disabled="ruleForm.storageType === 2"
          class="cmpNameSearch"
          filterable
          reserve-keyword
          placeholder="请选择"
          :remote-method="remoteMethodCust"
        >
          <el-option
            v-for="item in cmpNameArr"
            :key="item.cmpCode"
            :label="item.cmpName"
            :value="item.cmpCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="入库经办人" prop="inReceiver">
        <el-input
          v-model="ruleForm.inReceiver"
          placeholder="请输入入库经办人"
        />
      </el-form-item>
      <el-form-item label="入库时间" prop="inDate">
        <el-date-picker
          v-model="ruleForm.inDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptionsStart"
        />
      </el-form-item>
      <el-form-item label="送货人">
        <el-input
          v-model="ruleForm.driverName"
          placeholder="请输入送货人姓名"
        />
      </el-form-item>
      <el-form-item label="送货人电话">
        <el-input
          v-model="ruleForm.driverPhone"
          type="number"
          placeholder="请输入送货人电话"
        />
      </el-form-item>
      <el-form-item label="车牌号">
        <el-input
          v-model="ruleForm.carNo"
          placeholder="请输入车牌号"
        />
      </el-form-item>
      <el-form-item label="运单号">
        <el-input
          v-model="ruleForm.waybillNo"
          placeholder="请输入运单号"
        />
      </el-form-item>
      <!-- <el-form-item label="提单号" prop="deliveryNo">
        <el-input placeholder="请输入提单号" type="number" v-model="ruleForm.deliveryNo"></el-input>
      </el-form-item> -->
      <el-form-item label="备注">
        <el-input
          v-model="ruleForm.remark"
          type="textarea"
          maxlength="50"
          show-word-limit
          placeholder="请输入备注"
        />
      </el-form-item>
      <!-- <div class="button"> -->
      <h3 class="page-subtitle-shade">
        商品明细<span
          class="tip"
        >提示：如为订单入库，请先填写合同编号数据联动查询</span>
      </h3>
      <div class="list-operation">
        <div class="addButton">
          <el-button v-if="ruleForm.type !== '03'" size="small" type="primary" @click="addGoods">
            添加商品
          </el-button>
        </div>
        <el-upload
          v-if="ruleForm.type === '01'"
          :action="baseURL + 'wms/inventoryIn/import'"
          :before-upload="beforeAvatarUpload"
          style="display:inline-block"
          :show-file-list="false"
          :headers="headers"
          :on-success="handleUpload"
        >
          <el-button size="small" type="primary">
            明细导入
          </el-button>
        </el-upload>
        <a
          v-if="ruleForm.type === '01'"
          :href="baseURL + '/wms/inventoryIn/export?token=' + token"
          download="商品货位明细导入模板.xlsx"
        >
          <el-button size="small">
            模板下载
          </el-button>
        </a>
        <!-- </div> -->
      </div>
      <el-form-item class="lastOne" label=" ">
        <table class="table">
          <tr>
            <td>品名<i class="red">*</i></td>
            <td>材质<i class="red">*</i></td>
            <td>规格<i class="red">*</i></td>
            <td>产地<i class="red">*</i></td>
            <td>件重(吨)</td>
            <td>入库数量(吨)<i class="red">*</i></td>
            <td>计量单位<i class="red">*</i></td>
            <td>库区编号<i class="red">*</i></td>
            <td>货位号<i class="red">*</i></td>
            <td>货位描述</td>
            <td>下游客户名称</td>
            <!-- <td>钢卷号</td> -->
            <td v-if="!itemDisable">
              操作
            </td>
            <!-- <td>入库数量<i class="red">*</i></td> -->
          </tr>
          <tr v-for="(item, inde) in wmsInventoryIndetailList" :key="inde">
            <td>
              <span style="width:140px;display: inline-block;">{{ item.goodsName || '-' }}</span>
            </td>
            <td>
              <span style="width:140px;display: inline-block;">{{ item.materials || '-' }}</span>
            </td>
            <td>
              <span style="width:140px;display: inline-block;">{{ item.specs || '-' }}</span>
            </td>
            <td>
              <span style="width:140px;display: inline-block;">{{ item.productions || '-' }}</span>
            </td>
            <td>
              <span style="width:140px;display: inline-block;">{{ item.weights || '-' }}</span>
            </td>
            <td>
              <el-input-number
                v-model="item.inWeight"
                :precision="3"
                :controls="false"
                placeholder="请输入数字"
                :disabled="itemDisable"
              />
            </td>
            <td>
              <el-select
                v-model="item.unit"
                placeholder="请选择单位"
                :disabled="unitDisable"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="cl in $store.getters.getDictionaryItem('purchaseUnit')"
                  :key="cl.dictName"
                  :label="cl.dictName"
                  :value="cl.dictName"
                />
              </el-select>
            </td>
            <td>
              <el-select
                v-model="item.goodsYardNo"
                placeholder="请选择"
                @change="(val) => getAllocation(val, inde)"
              >
                <el-option
                  v-for="cl in goodsAllocationOption"
                  :key="cl.goodsYardNo"
                  :label="cl.goodsYardNo"
                  :value="cl.goodsYardNo"
                />
              </el-select>
            </td>
            <td>
              <el-select
                v-model="item.locationNo"
                placeholder="请选择"
                @change="(val) => getLocationNo(val, inde)"
              >
                <el-option
                  v-for="cl in item.locationNoOption"
                  :key="cl.locationNo"
                  :label="cl.locationNo"
                  :value="cl.locationNo"
                />
              </el-select>
            </td>
            <td>
              <el-input
                v-model="item.locationDesc"
                readonly
                maxlength="20"
              />
            </td>
            <td>
              <el-select
                v-if="isPort === '01'"
                v-model="item.downCustCmpCode"
                class="cmpNameSearch"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键字搜索"
                :remote-method="remoteMethodDownCust"
                :disabled="itemDisable"
                @change="(val) => getDownCustCmp(val, inde)"
              >
                <el-option
                  v-for="cl in downCustNameArr"
                  :key="cl.cmpCode"
                  :label="cl.cmpName"
                  :value="cl.cmpCode"
                />
              </el-select>
              <el-input
                v-else
                v-model="item.downCustName"
                readonly
                maxlength="20"
                placeholder="下游客户名称"
              />
            </td>
            <!-- <td>
              <el-input
                v-model="item.coilNo"
                maxlength="10"
                placeholder="请输入钢卷号"
              />
            </td> -->
            <td v-if="!itemDisable">
              <div style="width:88px">
                <!-- <i class="el-icon-circle-plus-outline" @click="addRow" /> -->
                <i class="el-icon-remove-outline" @click="deleteRow(inde)" />
              </div>
            </td>
            <!-- <td>
              <el-input v-model="item.inAmount" type="number" placeholder="请输入数字"></el-input>
            </td> -->
          </tr>
          <tr>
            <td
              v-if="
                wmsInventoryIndetailList &&
                  wmsInventoryIndetailList.length === 0
              "
              colspan="12"
            >
              没有数据，请添加！
            </td>
          </tr>
        </table>
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button
        :loading="$store.state.loading"
        size="small"
        type="primary"
        class="widen-button"
        @click="submitForm('ruleForm')"
      >
        确定
      </el-button>
      <el-button
        size="small"
        class="widen-button"
        @click="resetForm('ruleForm')"
      >
        重置
      </el-button>
    </div>
    <ChooseMatterWeight
      ref="chooseMatterWeight"
      :show-radio="false"
      :is-port="isPort"
      :selected-arr="wmsInventoryIndetailList"
      :contract-number="ruleForm.contractNo"
      @getCheckId="getCheckId"
    />
  </div>
</template>

<script>
import { baseURL } from '@/systemConfiguration/index'
import ChooseMatterWeight from '@/components/ChooseMatterWeight'
import { getGoodsList } from '@/api/goods'
import { warehouseList, addInventoryIn, warehouseGetYarnAndArea } from '@/api/warehousingSystem'
import { agentPurchaseContractExWarehouseInfo } from '@/api/generationMining'
import { getCmpBaseinfoPassList } from '@/api/memberManagement'
import { rules, formPattern } from '@/utils/matchFormRegex'
import { centralizedPurchaseProjectInvPage } from '@/api/centralizedPurchase'
export default {
  components: { ChooseMatterWeight },
  data() {
    return {
      headers: {
        token: localStorage.getItem('logintoken')
      },
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        }
      },
      ruleForm: {
        driverName: '',
        carNo: '',
        custId: '',
        deliveryNo: '',
        driverPhone: '',
        inDate: '',
        inReceiver: '',
        contractNo: '',
        warehouseId: '',
        remark: '',
        waybillNo: ''
      },
      baseURL,
      isPort: '01',
      changePnameIndex: null,
      rules: {
        storageType: [{ required: true, message: '请选择', trigger: 'change' }],
        custId: [
          { required: true, message: '请输入选择一个货主', trigger: 'change' }
        ],
        carNo: [...rules.carNo],
        contractNo: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        driverName: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' },
          { ...formPattern.allChinese },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        inReceiver: [
          { required: true, message: '请输入入库经办人', trigger: 'blur' }
        ],
        deliveryNo: [
          {
            min: 8,
            max: 8,
            message: '请输入8位纯数字的提单号',
            trigger: 'blur'
          }
        ],
        // invAmount: [
        //   { required: true, message: '请输入库存数量', trigger: 'blur' },
        // ],
        inDate: [{ required: true, message: '请选择时间', trigger: 'change' }],
        waybillNo: [
          { required: true, message: '请输运单号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        invWeight: [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ],
        driverPhone: [...rules.phone],
        warehouseId: [
          { required: true, message: '请输入选择仓库', trigger: 'change' }
        ],
        goodsArr: [{ required: true, message: '请选择品名', trigger: 'change' }]
      },
      pnameData: [],
      data: [],
      token: localStorage.getItem('logintoken'),
      cmpNameArr: [],
      wmsInventoryIndetailList: [],
      warehouseListArr: [],
      breadcrumbItem: [
        { path: '/', name: '首页' },
        { path: '/warehousingSystem/stockManagement', name: '入库管理' },
        { path: '', name: '新增入库' }
      ],
      goodsAllocationOption: [],
      locationNoOption: [],
      downCustNameArr: [],
      itemDisable: false,
      unitDisable: false
    }
  },
  mounted() {
    getGoodsList(
      res => {
        // 通过code为六位数的就是品名  取到这个就是第三级
        this.pnameData = res.data.filter(item => item.code.length === 6)
      },
      () => {},
      ''
    )
    this.remoteMethodCust()
    this.remoteMethod2()
    this.getDate()
  },
  methods: {
    // 获取下游用户名
    getDownCustCmp(val, index) {
      this.wmsInventoryIndetailList[index].downCustName = (this.downCustNameArr || []).filter((item) => item.cmpCode === val)[0].cmpName
    },
    getDate() {
      var now = new Date()
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() + 1 // 得到月份
      var date = now.getDate() // 得到日期
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.$set(this.ruleForm, 'inDate', defaultDate)
    },
    // 联查接口
    getContractNo(type) {
      this.wmsInventoryIndetailList = []
      if (type === 1) {
        this.isPort = this.ruleForm.type = '01'
        this.itemDisable = false
        this.unitDisable = false
        this.ruleForm.driverName = ''
        this.ruleForm.carNo = ''
        this.ruleForm.custId = ''
        this.ruleForm.deliveryNo = ''
        this.ruleForm.driverPhone = ''
        this.ruleForm.inReceiver = ''
        this.ruleForm.contractNo = ''
        this.ruleForm.warehouseId = ''
        this.ruleForm.waybillNo = ''
        this.ruleForm.remark = ''
      }
      if (this.ruleForm.storageType === 2 && type === 2 && this.ruleForm.contractNo) {
        agentPurchaseContractExWarehouseInfo(
          { contractNo: this.ruleForm.contractNo },
          res => {
            this.remoteMethod2(res.data.deliveryWarehouseName)
            this.remoteMethodCust(res.data.fundsCmpName)
            this.addGoodsAllocation(res.data.deliveryWarehouseId)
            this.$nextTick(() => {
              this.ruleForm.custId = res.data.fundsCmpCode
              this.ruleForm.warehouseId = res.data.deliveryWarehouseId
              this.ruleForm.warehouseName = res.data.deliveryWarehouseName
              this.ruleForm.custName = res.data.fundsCmpName
              this.ruleForm.downstreamCustomer = res.data.custCmpName
              this.ruleForm.downstreamCustCmpCode = res.data.custCmpCode
              this.isPort = this.ruleForm.type = res.data.type
              if (res.data.type === '02') this.unitDisable = true
              if (res.data.type === '03') {
                this.itemDisable = true
                this.unitDisable = true
                this.getCentralizedPurchaseProjectInvPage()
              } else {
                this.itemDisable = false
              }
              if (
                res.data.goodsDetailList &&
                res.data.goodsDetailList.length === 0
              ) {
                this.$message.error('该订单已入库完成')
              }
            })
            this.$forceUpdate()
          },
          () => {
            this.ruleForm.custId = ''
            this.ruleForm.warehouseId = ''
            this.ruleForm.warehouseName = ''
            this.ruleForm.custName = ''
            this.ruleForm.downstreamCustomer = ''
          }
        )
      }
    },
    // 获取集采关联商品
    getCentralizedPurchaseProjectInvPage() {
      centralizedPurchaseProjectInvPage(
        { contractNo: this.ruleForm.contractNo },
        res => {
          const unitName = (this.$store.getters.getDictionaryItem('purchaseUnit') || []).find((item) => item.dictId === res.data.meterageUnit).dictName
          const list = res.data.list.map((item) => ({
            id: res.data.id,
            goodsId: res.data.goodsId,
            goodsName: res.data.goodsName,
            materials: res.data.materials,
            specs: res.data.specs,
            productions: res.data.productions,
            weights: res.data.weights || '-',
            unit: unitName,
            warehouseNo: this.ruleForm.warehouseId,
            warehouseName: this.ruleForm.warehouseName,
            ...item
          }))
          this.wmsInventoryIndetailList = list
        }
      )
    },
    // 获取品名的名字
    getGoodsName(id) {
      if (!id) return ''
      const obj = this.pnameData.find(item => item.code === id)
      return obj.goodsName
    },
    // 上传之前的函数判断一下是否是xlsx格式
    beforeAvatarUpload(file) {
      const arr = file.name.split('.')
      if (arr[1] !== 'xlsx' && arr[1] !== 'xls') {
        this.$message.error('请选择xlsx格式文件或者xls格式文件！')
        return false
      }
    },
    // 上传成功的函数
    handleUpload(response) {
      if (response.code !== 200) {
        this.$message.error(response.msg || response.message)
        if (response.code === '1000' || response.code === 1000) return this.$router.push('/login')
        return
      }
      const arr = response.data
      arr.forEach(item => {
        item.goodsIds = item.goodsName
      })
      this.wmsInventoryIndetailList.unshift(...arr)
    },
    addGoods() {
      if (!this.ruleForm.storageType) {
        this.$message.error('请选择入库类型')
        return
      }
      if (this.ruleForm.storageType === 2 && !this.ruleForm.contractNo) {
        this.$message.error('请输入合同单号')
        return
      }
      if (!this.ruleForm.warehouseId) return this.$message.error('请选择仓库')
      this.$refs.chooseMatterWeight.dialogTableVisible = true
    },
    // 输入件重  材质  产地规格等的验证
    verificationPname(item, index) {
      this.changePnameIndex = index
      if (!item.goodsIds) {
        this.$message.error('请先选择品名')
        return
      }
      this.$refs.chooseMatterWeight.dialogTableVisible = true
      // 进行判断  如果品名是我们库里有的就弹窗  没有的就输入
      // let flag = this.pnameData.length === 0 ? false : this.pnameData.some(value => value.code === item.goodsIds)
      // if (flag) {
      //   this.$refs.chooseMatterWeight.dialogTableVisible = true
      // }
    },
    // 得到弹窗数据之后
    getCheckId(value) {
      this.wmsInventoryIndetailList = value ? [...value] : []
      this.wmsInventoryIndetailList.forEach(item => {
        // if (!item.weights || item.weights === -1 || item.weights === '-1' || item.weights === '不限') {
        //   item.weights = '-'
        // }
        // if (!item.productions || item.productions === -1 || item.productions === '-1' || item.productions === '不限') {
        //   item.productions = '-'
        // }
        // item.skuId = item.id
        if (this.isPort === '01') item.unit = '吨'
        if (this.isPort === '02') {
          item.downCustName = this.ruleForm.downstreamCustomer
          item.downCustCmpCode = this.ruleForm.downstreamCustCmpCode
          if (Number(item.unit))item.unit = ((this.$store.getters.getDictionaryItem('purchaseUnit') || []).find((cl) => cl.dictId === item.unit).dictName) || ''
        }
        // item.downCustName = this.ruleForm.downstreamCustomer
        item.warehouseNo = this.ruleForm.warehouseId
      })
      this.remoteMethodDownCust()
    },
    // 删除单个的库存货位明细
    deleteRow(index) {
      // if (this.wmsInventoryIndetailList.length === 1) {
      //   this.$message.error('入库商品不能为空')
      //   return
      // }
      this.wmsInventoryIndetailList.splice(index, 1)
    },
    // 联动搜搜货主列表
    remoteMethodCust(value) {
      getCmpBaseinfoPassList(value || '', res => {
        this.cmpNameArr = [...res.data]
      })
    },
    // 明细列表下游客户
    remoteMethodDownCust(value) {
      getCmpBaseinfoPassList(value || '', res => {
        this.downCustNameArr = res.data.filter(item => item.cmpRoleList.includes('09'))
      })
    },
    // 联动搜搜仓库列表
    remoteMethod2(value) {
      warehouseList(value || '', res => {
        this.warehouseListArr = [...res.data]
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.wmsInventoryIndetailList.length === 0 || !this.wmsInventoryIndetailList) return this.$message.error('入库商品不能为空')
          // 获取货主名称
          this.cmpNameArr.forEach(item => {
            if (item.cmpCode === this.ruleForm.custId) { this.ruleForm.custName = item.cmpName }
          })
          // 获取仓库编码
          this.warehouseListArr.forEach(item => {
            if (item.id === this.ruleForm.warehouseId) { this.ruleForm.warehouseName = item.warehouseName }
          })
          // 判断每个库存货位明细不为空
          let flag = true
          // 普通入库的情况下
          if (this.ruleForm.type === '01') {
            flag = this.wmsInventoryIndetailList.every(
              item =>
                item.productions &&
                item.specs &&
                item.materials &&
                item.inWeight &&
                item.goodsName &&
                item.warehouseNo &&
                item.goodsYardNo &&
                item.locationNo
            )
          }
          // 订单入库的情况下
          if (this.ruleForm.type === '02') {
            const arr = this.wmsInventoryIndetailList.filter(
              item => Number(item.inWeight) > 0
            )
            if (arr.length === 0) {
              flag = false
              this.$message.error('入库商品总数量不能为0')
              return
            }
            flag = arr.every(
              item => item.inWeight && item.locationNo && item.goodsYardNo && item.warehouseNo && item.unit
            )
          }

          if (!flag) {
            this.$message.error('请完善你要入库商品的必填信息')
            return
          }
          // if (!this.ruleForm.goodsWeightsId) {
          //   this.$message.error('请选择件重！');
          //   this.$refs.chooseMatterWeight.dialogTableVisible = true
          //   return
          // }
          // 把数量大于0的就提交一下了
          this.ruleForm.wmsInventoryIndetailList = [
            ...this.wmsInventoryIndetailList.filter(item => Number(item.inWeight) > 0)
          ]
          // this.ruleForm.wmsInventoryIndetailList.forEach(item => {
          //   if (item.weights === '-') {
          //     item.weights = ''
          //   }
          //   if (!item.productions || item.productions === '不限' || item.productions === '-') {
          //     item.productions = ''
          //   }
          // })
          addInventoryIn(this.ruleForm, () => {
            this.$message.success('新增成功！')
            this.$router.go(-1)
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.wmsInventoryIndetailList = []
    },
    // 新增货位号弹窗显示
    addGoodsAllocation(id) {
      warehouseGetYarnAndArea(this.ruleForm.warehouseId || id || '', res => {
        if (!res.data.id || !res.data.wmsYardRespList) return
        this.goodsAllocationOption = [...res.data.wmsYardRespList]
      })
    },
    // 选择关联库区
    getAllocation(val, index) {
      if (!val) return
      this.goodsAllocationOption.forEach((item) => {
        if (item.goodsYardNo === val) {
          this.wmsInventoryIndetailList[index].goodsYardRemark = item.goodsYardRemark
          this.wmsInventoryIndetailList[index].locationNoOption = item.wmsAreaRespList
        }
      })
      this.$set(this.wmsInventoryIndetailList[index], 'locationNo', '')
    },
    // 选择货位号
    getLocationNo(val, index) {
      if (!val) return
      this.wmsInventoryIndetailList[index].locationNoOption.forEach((item) => {
        if (item.locationNo === val) {
          this.wmsInventoryIndetailList[index].locationDesc = item.locationDesc
        }
      })
    }
  }
}
</script>

<style lang="scss">
.stockStorage .buttonCombination {
  text-align: center;
}

.stockStorage .tip {
  color: red;
  font-size: 12px;
  margin-left: 12px;
}

.stockStorage .el-form .lastOne {
  width: 100%;

  .el-form-item__label {
    color: #333;
    font-weight: 700;
    width: 100px !important;
  }

  .el-form-item__content {
    display: block;
    text-align: center;
    padding: 0 12px 16px;
    width: calc(100% - 64px);
    overflow-y: auto;
  }
}

.stockStorage .table {
  width: 100%;
  border-collapse: collapse;
}

.stockStorage .table td {
  border: 1px solid #ededed;
  text-align: center;

  .red {
    color: red;
    margin-left: 2px;
    font-size: 13px;
  }
}

.stockStorage .table td .el-input,
.stockStorage .table td input {
  width: 160px;
}

.stockStorage .table td i {
  font-size: 26px;
  cursor: pointer;
  line-height: 40px;
}

.stockStorage .table td .el-input .el-input__inner {
  border: 0;
  text-align: center;
}

.stockStorage .page-subtitle-shade {
  margin-bottom: 0px !important;
}

.stockStorage .list-operation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  .addButton{
    flex: 1;
  }

  .el-button {
    margin-left: 10px;
  }
}
</style>
