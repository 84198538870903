<template>
  <!-- 品名选择 -->
  <el-dialog class="ChooseMatterWeight" title="请选择商品" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
    <span class="sub-title">找不到商品？请联系运营人员添加</span>
    <!-- 选项框 -->
    <div class="searchBox">
      <!-- 选择品名 -->
      <ProductName :default-value="defaultValue" @getProduct="getProduct" />
      <!-- 选择材质 -->
      <el-select v-model="formInline.materials" size="small" filterable placeholder="请选择材质">
        <el-option
          v-for="(item, ind) in materialsArr"
          :key="ind"
          :label="item.materials"
          :value="item.materials"
        />
      </el-select>
      <el-input v-model="formInline.specs" size="small" placeholder="请输入规格" />
      <div class="button">
        <el-button
          size="small"
          class="high-button"
          type="primary"
          @click="getData(false)"
        >
          搜索
        </el-button>
        <el-button
          size="small"
          class="high-button"
          type="primary"
          @click="resetForm"
        >
          重置
        </el-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      border
      :data="gridData"
      max-height="380"
      @select="selectionRow"
      @select-all="selectionAllRow"
    >
      <template slot="empty">
        <span>请重新选择或者进行添加</span>
      </template>
      <el-table-column v-if="showRadio" label=" " width="42">
        <template slot-scope="scope">
          <el-radio v-model="value" :label="scope.row">
            .
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column v-else type="selection" width="55" />
      <el-table-column property="goodsName" label="品种">
        <template slot-scope="scope">
          {{ scope.row.goodsName||'-' }}
        </template>
      </el-table-column>
      <el-table-column property="materials" label="材质">
        <template slot-scope="scope">
          {{ scope.row.materials||'-' }}
        </template>
      </el-table-column>
      <el-table-column property="specs" label="规格">
        <template slot-scope="scope">
          {{ scope.row.specs||'-' }}
        </template>
      </el-table-column>
      <el-table-column property="productions" label="产地">
        <template slot-scope="scope">
          {{ scope.row.productions||'-' }}
        </template>
      </el-table-column>
      <el-table-column property="weights" label="件重(吨)">
        <template slot-scope="scope">
          {{ scope.row.weights||'-' }}
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pagination
      :params="formInline"
      :total="total"
      :in-article="gridData.length"
      :get-data-list="getData"
    />
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogTableVisible = false">
        取 消
      </el-button>
      <el-button size="small" type="primary" @click="getCheckId">
        确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { goodsWeightsListByGoodsId, getGoodsMaterialsList, agentPurchaseContractContractInvPage } from '@/api/goods'
import { centralizedPurchaseProjectInvPage } from '@/api/centralizedPurchase'
import Pagination from './Pagination2'
import ProductName from './ProductName'
export default {
  components: { Pagination, ProductName },
  props: {
    id: {
      default: '',
      type: String
    },
    showRadio: {
      default: true,
      type: Boolean
    },
    contractNumber: {
      default: '',
      type: String
    },
    isPort: {
      default: '01',
      type: String
    }, // 用哪个接口
    selectedArr: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      gridData: [],
      materialsArr: [],
      value: '',
      dialogTableVisible: false,
      defaultValue: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        goodsName: '',
        materials: '',
        productions: '',
        specs: ''
      },
      selectArr: [],
      total: 0
    }
  },
  watch: {
    dialogTableVisible: {
      handler(newVal) {
        if (newVal) {
          this.getData()
          this.value = null
          this.selectArr = [...this.selectedArr]
          return
        }
        if (this.selectedArr.length === 0 && this.$refs['multipleTable']) this.$refs['multipleTable'].clearSelection()
      }
    },
    gridData: {
      handler() {
        this.$nextTick(() => {
          this.selectArr.forEach(row => {
            const obj = this.gridData.find(item => row.id === item.id || row.goodsWeightsId === item.id)
            if (obj && obj.id) this.$refs['multipleTable'].toggleRowSelection(obj, true)
          })
        })
      },
      deep: true,
      immediate: true
    },
    id: {
      handler(newVal) {
        if (newVal) {
          this.formInline.goodsId = newVal
          this.getData()
        }
      }
    }
  },
  methods: {
    // 重置按钮
    resetForm() {
      this.defaultValue = []
      this.materialsArr = []
      this.getData('reset')
    },
    // 修改品名的时候
    getProduct(obj) {
      if (obj.value.length === 0) return
      this.formInline.goodsId = obj.value[2] || ''
      this.formInline.goodsName = obj.label[2] || ''
      getGoodsMaterialsList({ state: 1, categoryId: obj.value[0], varietyId: obj.value[1], goodsId: obj.value[2] }, res => {
        this.materialsArr = [...res.data]
      })
    },
    // 全选全不选
    selectionAllRow(arr) {
      // 不选的时候   就把id从列表中拿出来   从已经选了的中剔除出来
      if (arr.length === 0) {
        const idArr = []
        this.gridData.forEach(item => {
          idArr.push(item.id)
        })
        this.selectArr = this.selectArr.filter(item => !idArr.includes(item.id))
        return
      }
      // 全选的时候  进行拼接然后去重
      this.selectArr = this.unique(this.selectArr.concat(arr))
    },
    // 数组去重
    unique(arr) {
      const res = new Map()
      return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1))
    },
    selectionRow(arr, row) {
      let flag = true
      // 小于的时候就是勾选为false   就在删除的中加上一条  否则减去一条
      this.selectArr.forEach((item, index) => {
        if (item.id === row.id) {
          this.selectArr.splice(index, 1)
          flag = false
        }
      })
      if (flag) this.selectArr.push(row)
    },
    getData(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      if (this.contractNumber) this.formInline.contractNo = this.contractNumber
      const api = this.isPort === '01' ? goodsWeightsListByGoodsId : this.isPort === '02' ? agentPurchaseContractContractInvPage : centralizedPurchaseProjectInvPage
      api(this.formInline, (res) => {
        this.gridData = (res.data.pageData || res.data.records || []).map((item) => {
          // if (!item.weights || item.weights === -1 || item.weights === '-1' || item.weights === '不限' || item.weights === '-') {
          //   item.weights = '-'
          // }
          // if (!item.productions || item.productions === -1 || item.productions === '-1' || item.productions === '不限' || item.productions === '-') {
          //   item.productions = '-'
          // }
          return item
        })
        this.total = res.data.total
      })
    },
    getCheckId() {
      if (!this.value && this.showRadio) {
        this.$message.error('请选择一个！')
        return
      }
      if (!this.showRadio && this.selectArr.length === 0) {
        this.$message.error('请选择一个！')
        return
      }
      this.dialogTableVisible = false
      this.$emit('getCheckId', this.showRadio ? this.value : this.selectArr)
    }
  }
}
</script>

<style lang="scss">
.ChooseMatterWeight {
  .searchBox{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    >div{
      flex: 1;
      margin-right: 8px;
    }
    .button{
      text-align: right;
    }
  }
  .el-radio__label {
    display: none;
  }
  .dialog-footer{
    text-align: center;
  }
  .el-table__empty-text{
    width: 100%;
  }
   .sub-title {
    position: absolute;
    top: 22px;
    left: 120px;
  }
}
</style>
