import { postRequest } from './index'
import { SCMAPI } from '@/systemConfiguration/index'

// 集采入库时商品分页查询
export const centralizedPurchaseProjectInvPage = (data, successCallback, failureCallback) => {
  postRequest(`${SCMAPI}/v2/centralizedPurchase/project/projectInvPage`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

